@import '../../../../scss/theme-bootstrap';

.product-auto-replenishment {
  &__heading {
    margin-top: 0;
  }
  // @TODO Where is this being used? If these are classes being applied in cms we should give it more specific classNames i.e. .product-auto-replenishment__terms or use helper classes
  .product-auto-replenishment__description {
    .terms-conditions-title {
      @include link;
    }
    .auto-delivery-image-wrapper {
      text-align: center;
    }
    .auto-delivery-image-pc {
      width: 100%;
      max-width: 846px;
      display: none;
      @include breakpoint($landscape-up) {
        display: inline-block;
      }
    }
    .auto-delivery-image-mobile {
      display: inline-block;
      width: 100%;
      max-width: 786px;
      @include breakpoint($landscape-up) {
        display: none;
        width: auto;
        max-width: none;
      }
    }
  }
}
